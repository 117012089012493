(function ($) {
  "use strict";
});

function navigation() {
  // //console.log( 'hello' );
  $(".site-navigation ul ul")
    .find("li")
    .each(function () {
      if ($(this).children("ul").length) {
        $(this).children("a").addClass("hasChildren");
      }
    });
}
